import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'
import KareGozluTablo from '../contents/tables/KareGozluTablo'
import DikdortgenGozluTablo from '../contents/tables/DikdortgenGozluTablo'
import OlcuAlert from '../contents/alert/OlcuAlert'

const HasirTelPage = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Hasır Tel - Erez Kaynak'
        description='Paslanmaz, galvanize yada çelik telden, müşterilerimizin istekleri doğrultusun da istenilen tel kalınlığında ve ebatta, göz aralığı fark etmeksizin punta kaynaklı hasır tel (çesan) imalatı gerçekleştirmekteyiz.'
        keywords='hasır tel,hasır tel fiyatları,hasır tel imalatı,hasır tel kullanım alanları,kafes tel kullanım alanları,tel kafes kullanım alanları,paslanmaz hasır tel,hasır telleri,puntalı hasır tel,punta kaynaklı hasır tel,puntalı hasır tel fiyatları,hasır,tel,hasır tel imalatçısı,özel hasır tel,galvaniz hasır tel,hasır tel istanbul,tel hasır,kafes tel,tel hasır fiyatları,kafes tel fiyatları,hasır tel fiyatı'
        url='hasir-tel'
        imgurl={getImageByName('galvanizhasirtel11').sm.src}
      />
      <ImgLightBox ref={lightBoxRef} gallery={[getImageByName('galvanizhasirtel11').original.src]} />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Hasır Tel İmalatı' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Paslanmaz, galvanize ya da çelik telden, müşterilerimizin istekleri doğrultusunda istenilen tel kalınlığında ve ebatta, göz
                aralığı fark etmeksizin <strong>punta kaynaklı hasır tel (çesan) imalatı</strong> gerçekleştirmekteyiz.
              </p>
              <p>
                Makinelerimizin kalıpları en fazla <strong>2100x3000mm</strong> ebatında olup, göz aralıkları{' '}
                <strong>15mm, 20mm, 25mm </strong>ölçülerinde ve katlarında <strogn>(Örn; 25x50mm 50x50mm 15x45mm 15x75mm vb.)</strogn> ya
                da siparişe göre özel kalıp yapılarak istenilen göz aralığında, paslanmaz, galvanize ya da siyah çelik telden{' '}
                <strong>hasır tel</strong> üretimi yapılmaktadır.
              </p>
              <p>
                Hasır tellere <strong>Elektrostatik</strong> toz fırın boya işlemi yapılabilmektedir. Standart yada özel renk seçenekleri
                ile hasır teller elektrostatik toz boya ile boyanmaktadır. Firmamız bünyesinde boyasız olarak hasır tel imalatı
                gerçekleştirip, çalışmış olduğumuz elektrostatik toz boya yapan firmalar ile ihtiyacınız karşılanmaktadır.
              </p>
            </ContentText>
            <Grid container justify='center' spacing={3}>
              <Grid item>
                <InnerLink title='Paslanmaz Hasır Tel' url='/paslanmaz-hasir-tel' />
              </Grid>
              <Grid item>
                <InnerLink title='Galvaniz Hasır Tel' url='/galvaniz-hasir-tel' />
              </Grid>
              <Grid item>
                <InnerLink title='Çelik Hasır Tel' url='/celik-hasir-tel-imalati' />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('galvanizhasirtel11')} alt='çelik hasır tel imalatı erez kaynak' />
            </ImgWrapper>
          </Grid>
        </Grid>
      </Box>
      <GeneralCarousel />
      <Box p={3}>
        <OlcuAlert />
      </Box>
      <Box p={3} mb={5}>
        <KareGozluTablo />
      </Box>
      <Box p={3} mb={5}>
        <DikdortgenGozluTablo />
      </Box>
    </>
  )
}
export default HasirTelPage
